import React from "react";
import Layout from "../../components/layout";
import MarcaPage from "../../components/marcaPage";
import UniversaBanner from "../../images/desktop/marcas/universal/universalBanner.png";
import JurasicW from "../../images/desktop/marcas/universal/jurasica-w.png";
import TrollsDark from "../../images/desktop/marcas/universal/trollsblack.png";
import BabyDream from "../../images/desktop/marcas/universal/babydream.png";
import Kou from "../../images/desktop/marcas/universal/kou.png";
import Back2 from "../../images/desktop/marcas/universal/back2.png";
import Et from "../../images/desktop/marcas/universal/et.png";
import LaCasa from "../../images/desktop/marcas/universal/lacasade.png";
import Monsters from "../../images/desktop/marcas/universal/monsters.png";
import Waldo from "../../images/desktop/marcas/universal/waldo.png";
import Jurassic from "../../images/desktop/marcas/universal/jurassic.png";
import Jaws from "../../images/desktop/marcas/universal/jaws.png";
import Woody from "../../images/desktop/marcas/universal/woody.png";
import LittleLulu from "../../images/desktop/marcas/universal/littlelulu.png";
import GabbysDoll from "../../images/desktop/marcas/universal/gabbysdollhouse.png";
import Pets from "../../images/desktop/marcas/universal/pets.png";
import Spirit from "../../images/desktop/marcas/universal/spirit.png";
import Madagascar from "../../images/desktop/marcas/universal/madagascar.png";
import Sherk from "../../images/desktop/marcas/universal/shrek.png";
import Dragons from "../../images/desktop/marcas/universal/dragons.png";
import Olivia from "../../images/desktop/marcas/universal/olivia.png";
import Felix from "../../images/desktop/marcas/universal/felix.png";
import Fast from "../../images/desktop/marcas/universal/fastfurious.png";
import Puss from "../../images/desktop/marcas/universal/puss.png";
import Sing from "../../images/desktop/marcas/universal/sing.png";
import Casper from "../../images/desktop/marcas/universal/casper.png";
import Curious from "../../images/desktop/marcas/universal/curiousgorge.png";
import Minions from "../../images/desktop/marcas/universal/minions.png";

const UniversalPage = () => {
  return (
    <Layout>
      <MarcaPage backgroundImage={UniversaBanner}>
        <img alt="marca" src={BabyDream} />
        <img alt="marca" src={Back2} />
        <img alt="marca" src={Casper} />
        <img alt="marca" src={Curious} />
        <img alt="marca" src={Dragons} />
        <img alt="marca" src={Et} />
        <img alt="marca" src={Fast} />
        <img alt="marca" src={Felix} />
        <img alt="marca" src={GabbysDoll} />
        <img alt="marca" src={Jaws} />
        <img alt="marca" src={Jurassic} />
        <img alt="marca" src={JurasicW} />
        <img alt="marca" src={Kou} />
        <img alt="marca" src={LaCasa} />
        <img alt="marca" src={LittleLulu} />
        <img alt="marca" src={Madagascar} />
        <img alt="marca" src={Minions} />
        <img alt="marca" src={Monsters} />
        <img alt="marca" src={Olivia} />
        <img alt="marca" src={Pets} />
        <img alt="marca" src={Puss} />
        <img alt="marca" src={Sherk} />
        <img alt="marca" src={Sing} />
        <img alt="marca" src={Spirit} />
        <img alt="marca" src={TrollsDark} />
        <img alt="marca" src={Waldo} />
        <img alt="marca" src={Woody} />
      </MarcaPage>
    </Layout>
  );
};

export default UniversalPage;
